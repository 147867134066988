import axios from 'axios'
import React from 'react'
import CONSTANTS from '../../settings/constants'
import message from './../../components/common/feedback/message'

// Action Names
export const FETCH_COMPANIES_BY_AGENT = 'FETCH_COMPANIES_BY_AGENT'
export const FETCH_COMPANIES_OBJECT_BY_AGENT = 'FETCH_COMPANIES_OBJECT_BY_AGENT'
export const FETCH_AGENTS = 'FETCH_AGENTS'
export const FETCH_AGENTS_BY_COMPANY = 'FETCH_AGENTS_BY_COMPANY'
export const GET_ALL_AGENTS = 'GET_ALL_AGENTS'
export const GET_ROLES = 'GET_ROLES'
export const GET_GROUPS = 'GET_GROUPS'
export const GET_USER_INFO = 'GET_USER_INFO'

// API URLs
const {XONE_CASH_API, AGENT_ROLE_NAME} = CONSTANTS

export const fetchCompaniesByAgent = (agentId, token) => async (dispatch) => {
    const {data} = await axios.get(
        `${XONE_CASH_API}/v1/queueManager/zones/findByAgentExternalId/${agentId}`,
        {headers: {'Authorization': 'Bearer ' + token}}
    )
    const companyIds = !data ? [] : data.map((company) => company.id)
    dispatch({type: FETCH_COMPANIES_BY_AGENT, payload: companyIds})
}

export const fetchCompaniesObjectByAgent = (agentId, token) => async (dispatch) => {
    const {data} = await axios.get(
        `${XONE_CASH_API}/v1/queueManager/zones/findByAgentExternalId/${agentId}`,
        {headers: {'Authorization': 'Bearer ' + token}}
    )
    dispatch({type: FETCH_COMPANIES_OBJECT_BY_AGENT, payload: data || []})
}

export const fetchAgents = (token, companyId = null) => async (dispatch) => {
    let url = `${XONE_CASH_API}/v1/agents/findByRoleName/${AGENT_ROLE_NAME}`
    if (!!companyId) {
        url += `/${companyId}`
    }
    const {data} = await axios.get(
        url, {headers: {'Authorization': 'Bearer ' + token}}
    )
    dispatch({type: FETCH_AGENTS, payload: data || []})
}

export const fetchAgentsByCompany = (companyId, groupId, token) => async (dispatch) => {
    const {data} = await axios.get(
        `${XONE_CASH_API}/v1/queueManager/zones/findAgentsByZoneExternalId`,
        {
            headers: {'Authorization': 'Bearer ' + token},
            params: {
                zoneExternalId: companyId,
                groupId: groupId
            }
        }
    )
    dispatch({type: FETCH_AGENTS_BY_COMPANY, payload: data || []})
}

export const getAllAgents = (token, filtersData = null, page = null, size = null) => async (dispatch) => {
    let url = `${XONE_CASH_API}/v1/agents/getAll`
    if (!!filtersData) {
        const {name, email, status} = filtersData
        if (!!name) {
            url += `?name=${name}`
        }
        if (!!email) {
            if (!!name) {
                url += `&email=${email}`
            } else {
                url += `?email=${email}`
            }
        }
        if (!!status) {
            if (!email && !name) {
                url += `?status=${status}`
            } else {
                url += `&status=${status}`
            }
        }
    }
    if (!!page) {
        if (!filtersData) {
            url += `?page=${page}`
        } else {
            url += `&page=${page}`
        }
    } else {
        if (!filtersData) {
            url += `?page=1`
        } else {
            url += `&page=1`
        }
    }
    if (!!size) {
        url += `&size=${size}`
    } else {
        url += `&size=10`
    }
    const {data} = await axios.get(url, {headers: {'Authorization': 'Bearer ' + token}})
    dispatch({type: GET_ALL_AGENTS, payload: data || []})
}

export const getRoles = (token) => async (dispatch) => {
    const {data} = await axios.get(
        `${XONE_CASH_API}/v1/agents/getAllRoles`,
        {headers: {'Authorization': 'Bearer ' + token}}
    )
    dispatch({type: GET_ROLES, payload: data})
}

export const getGroups = (token) => async (dispatch) => {
    const {data} = await axios.get(
        `${XONE_CASH_API}/v1/queueManager/assignmentGroup/findAll`,
        {headers: {'Authorization': 'Bearer ' + token}}
    )
    dispatch({type: GET_GROUPS, payload: data})
}

export const saveUser = (userData, token) => async (dispatch, getState) => {
    let url = `${XONE_CASH_API}/v1/agents/`
    let item = {}
    let jsonData = {}
    if (!!userData.company) {
        jsonData.companies = userData.company
    }
    if (!!userData.group) {
        jsonData.groups = userData.group
    }
    try {
        let {data} = {} // eslint-disable-line
        if (userData.userId === undefined) {
            url += 'create'
            item = {
                name: userData.agentName,
                email: userData.email,
                password: userData.password,
                agentRoleId: userData.roleId,
                jsonData
            }
            data = await axios.post(url, item, {headers: {'Authorization': 'Bearer ' + token}})
        } else {
            url += `update/${userData.userId}`
            item = {
                name: userData.agentName,
                email: userData.email,
                password: userData.password,
                agentRoleId: userData.roleId,
                jsonData,
                status: userData.status
            }
            data = await axios.put(url, item, {headers: {'Authorization': 'Bearer ' + token}})
        }
        dispatch(getAllAgents(token))
        message.success('El usuario ha sido guardado exitosamente')
    } catch (error) {
        message.error(
            (error.response.status === 422 && error.response.data.error)
                ? <span>{error.response.data.error.map((item) => item.value + '. ')} </span>
                : 'Ha ocurrido un error al guardar el agente'
        )
    }
}

export const suspendUser = (userId, token) => async (dispatch, getState) => {
    try {
        await axios.put(
            `${XONE_CASH_API}/v1/agents/disable/${userId}`, undefined,
            {headers: {'Authorization': 'Bearer ' + token}}
        )
        dispatch(getAllAgents(token))
        message.success('El usuario se ha suspendido exitosamente')
    } catch (error) {
        message.error('Ha ocurrido un error al suspender al agente')
    }
}

export const activateUser = (userId, token) => async (dispatch, getState) => {
    try {
        await axios.put(
            `${XONE_CASH_API}/v1/agents/enable/${userId}`, undefined,
            {headers: {'Authorization': 'Bearer ' + token}}
        )
        dispatch(getAllAgents(token))
        message.success('El usuario se ha activado exitosamente')
    } catch (error) {
        message.error(
            (error.response.status === 422 && error.response.data.error)
                ? <span>{error.response.data.error.map((item) => item.value + '. ')} </span>
                : 'Ha ocurrido un error al activar el agente'
        )
    }
}

export const changePassword = (userId, formData, token) => async () => {
    const axiosInstance = axios.create({
        baseURL: XONE_CASH_API,
        headers: {
            'Authorization': 'Bearer ' + token,
            'content-type': 'multipart/form-data'
        }
    })
    try {
        const response = await axiosInstance({
            method: 'put',
            url: `/v1/agents/changePassword/${userId}`,
            data: formData
        })
        if (response.data.success) {
            message.success('Se ha actualizado la contraseña correctamente')
        } else {
            message.error('Hubo un problema al intentar cambiar la contraseña')
        }
    } catch (error) {
        message.error('Hubo un problema al intentar cambiar la contraseña')
    }
}
