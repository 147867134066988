import axios from 'axios'
import React from 'react'
import CONSTANTS from '../../settings/constants'
import message from './../../components/common/feedback/message'

export const FETCH_CSV_OPTIONS = "FETCH_CSV_OPTIONS";
export const FETCH_CSV_IMPORT = "FETCH_CSV_IMPORT";
export const LOADER_TRUE = "LOADER_TRUE";
export const LOADER_FALSE = "LOADER_FALSE";

const {XONE_CASH_API} = CONSTANTS;

export const uploadCsv = (formData, token, next) => async (dispatch, getState) => {
    let url = `${XONE_CASH_API}/v1/importCsv/mapFields`;
    dispatch({type: LOADER_TRUE});
    try {
        let {data} = {}
        data = await axios.post(url, formData, {headers: {'Authorization': 'Bearer ' + token}})
        dispatch({type: FETCH_CSV_OPTIONS, payload: data})
        message.success('Archivo subido con éxito')
        next();
    } catch (error) {
        message.error(
            (error.response && error.response.status && error.response.status === 422 && error.response.data.error)
                ? <span>{error.response.data.error.map((item) => item.value + '. ')} </span>
                : 'Ha ocurrido un error al subir el archivo'
        )
    }
    dispatch({type: LOADER_FALSE});
}

export const importCsv = (formData, token, next) => async (dispatch, getState) => {
    let url = `${XONE_CASH_API}/v1/importCsv/import`;
    dispatch({type: LOADER_TRUE});
    try {
        let {data} = {}
        data = await axios.post(url, formData, {headers: {'Authorization': 'Bearer ' + token}})
        dispatch({type: FETCH_CSV_IMPORT, payload: data})
        message.success('Datos importados con éxito')
        next();
    } catch (error) {
        message.error(
            (error.response && error.response.status && error.response.status === 422 && error.response.data.error)
                ? <span>{error.response.data.error.map((item) => item.value + '. ')} </span>
                : 'Ha ocurrido un error al importar los datos'
        )
    }
    dispatch({type: LOADER_FALSE});
}
