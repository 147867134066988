import React from 'react';
import {Link} from 'react-router-dom';
import {siteConfig} from '../../../settings';

export default ({collapsed, userLevel}) => {
    return (
        <div className="isoLogoWrapper">
            {collapsed ? (
                <div>
                    <h3>
                        <Link to={userLevel === 1 ? "/app/csv-upload" : "/app/management-trays"}>
                            <i className={siteConfig.siteIcon}/>
                        </Link>
                    </h3>
                </div>
            ) : (
                <h3>
                    <Link
                        to={userLevel === 1 ? "/app/csv-upload" : "/app/management-trays"}>{siteConfig.siteName}</Link>
                </h3>
            )}
        </div>
    );
};
