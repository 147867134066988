import axios from 'axios'
import CONSTANTS from './../../settings/constants'
import message from './../../components/common/feedback/message'

// Action names
export const FETCH_PHONES_BY_ID = 'FETCH_PHONES_BY_ID'
export const GET_CALLS_BY_CONTACT = 'GET_CALLS_BY_CONTACT'
export const GET_ADDRESSES_BY_CONTACT = 'GET_ADDRESSES_BY_CONTACT'
export const GET_CITIES_BY_PROVINCE = 'GET_CITIES_BY_PROVINCE'
export const FETCH_EMAILS_BY_ID = 'FETCH_EMAILS_BY_ID'
export const GET_COMPANY_BY_ID = 'GET_COMPANY_BY_ID'

// API URLs
const { XONE_CASH_API } = CONSTANTS

export const clearContactStore = () => async (dispatch) => {
  dispatch({ type: FETCH_PHONES_BY_ID, payload: null })
  dispatch({ type: GET_CALLS_BY_CONTACT, payload: null })
  dispatch({ type: GET_ADDRESSES_BY_CONTACT, payload: null })
  dispatch({ type: FETCH_EMAILS_BY_ID, payload: null })
  dispatch({ type: GET_COMPANY_BY_ID, payload: null })
}

export const fetchPhonesById = (token, id = 0) => async (dispatch) => {
  const { data } = await axios.get(
    `${XONE_CASH_API}/v1/contacts/phoneNumbers/findByExternalId/${id}`,
    { headers: { 'Authorization': 'Bearer ' + token } }
  )
  dispatch({ type: FETCH_PHONES_BY_ID, payload: data || [] })
}

export const fetchEmailsById = (token, id = 0) => async (dispatch) => {
  const { data } = await axios.get(
    `${XONE_CASH_API}/v1/contacts/emails/findByExternalId/${id}`,
    { headers: { 'Authorization': 'Bearer ' + token } }
  )
  dispatch({ type: FETCH_EMAILS_BY_ID, payload: data || [] })
}

export const getCompanyBylId = (token, id = 0) => async (dispatch) => {
  const { data } = await axios.get(
    `${XONE_CASH_API}/v1/collection/company/findById?id=${id}`,
    { headers: { 'Authorization': 'Bearer ' + token } }
  )
  dispatch({ type: GET_COMPANY_BY_ID, payload: data || [] })
}

export const addPhoneNumber = (item, token) => async (dispatch, getState) => {
  try {
    var phone = item
    const { data } = await axios.post(
      `${XONE_CASH_API}/v1/contacts/phoneNumbers/create`, phone,
      { headers: { 'Authorization': 'Bearer ' + token } }
    )
    const { contacts } = getState()
    const { phoneList } = contacts
    const updatedPhoneList = [ ...phoneList, data ]
    dispatch({ type: FETCH_PHONES_BY_ID, payload: updatedPhoneList })
    message.success('El teléfono ha sido creado exitosamente')
  } catch (error) {
    message.error('Ha ocurrido un error al guardar el teléfono')
  }
}

export const deletePhoneNumber = (token, id = null) => async (dispatch, getState) => {
  try {
    await axios.delete(
      `${XONE_CASH_API}/v1/contacts/phoneNumbers/delete/${id}`,
      { headers: { 'Authorization': 'Bearer ' + token } }
    )
    const { contacts } = getState()
    const { phoneList } = contacts
    const updatedPhoneList = phoneList.filter(phone => phone.id !== id)
    dispatch({ type: FETCH_PHONES_BY_ID, payload: updatedPhoneList })
    message.success('El teléfono ha sido eliminado exitosamente')
  } catch (error) {
    message.error('Ha ocurrido un error al eliminar el teléfono')
  }
}

export const getCallsByContact = (token, externalId = null) => async (dispatch) => {
  const { data } = await axios.get(
    `${XONE_CASH_API}/v1/calls/findByCallReceptorId/${externalId}`,
    { headers: { 'Authorization': 'Bearer ' + token } }
  )
  dispatch({ type: GET_CALLS_BY_CONTACT, payload: data || [] })
}

export const updatePhoneNumber = (phoneNumber, token) => async (dispatch, getState) => {
  try {
    await axios.put(
      `${XONE_CASH_API}/v1/contacts/phoneNumbers/edit/${phoneNumber.id}`, phoneNumber,
      { headers: { 'Authorization': 'Bearer ' + token } }
    )
    message.success('El teléfono ha sido guardado exitosamente')
  } catch (error) {
    message.error('Ha ocurrido un error al guardar el teléfono')
  }
}

export const getAddressesByContact = (contactId, token) => async (dispatch) => {
  const { data } = await axios.get(
    `${XONE_CASH_API}/v1/contacts/addresses/findByExternalId/${contactId}`,
    { headers: { 'Authorization': 'Bearer ' + token } }
  )
  dispatch({ type: GET_ADDRESSES_BY_CONTACT, payload: data || [] })
}

export const addAddress = (item, token) => async (dispatch, getState) => {
  try {
    const address = item
    const { data } = await axios.post(
      `${XONE_CASH_API}/v1/contacts/address/create`, address,
      { headers: { 'Authorization': 'Bearer ' + token } }
    )
    const { contacts } = getState()
    const { addressList } = contacts
    const updatedAddressList = [ ...addressList, data ]
    dispatch({ type: GET_ADDRESSES_BY_CONTACT, payload: updatedAddressList })
    message.success('La dirección ha sido guardada')
  } catch (error) {
    message.error('Ha ocurrido un error al guardar la dirección')
  }
}

export const getCitiesByProvince = (token, provinceId = null) => async (dispatch) => {
  var url = `${XONE_CASH_API}/v1/city/findAll/`
  if (!!provinceId) {
    url += `${provinceId}`
  }
  const { data } = await axios.get(url, { headers: { 'Authorization': 'Bearer ' + token } })
  dispatch({ type: GET_CITIES_BY_PROVINCE, payload: data })
}
