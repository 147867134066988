import React, {Component} from 'react';
import {connect} from 'react-redux';
import clone from 'clone';
import {Link} from 'react-router-dom';
import {Layout} from 'antd';
import options from './options';
import Scrollbars from '../../components/common/utility/customScrollBar.js';
import Menu from '../../components/common/uielements/menu';
import IntlMessages from '../../components/common/utility/intlMessages';
import SidebarWrapper from './sidebar.style';
import appActions from '../../redux/app/actions';
import Logo from '../../components/common/utility/logo';
import themes from '../../settings/themes';
import {themeConfig} from '../../settings';

const SubMenu = Menu.SubMenu;
const {Sider} = Layout;

const {
    toggleOpenDrawer,
    changeOpenKeys,
    changeCurrent,
    toggleCollapsed
} = appActions;

const stripTrailingSlash = str => {
    if (str.substr(-1) === '/') {
        return str.substr(0, str.length - 1);
    }
    return str;
};

class Sidebar extends Component {
    constructor(props) {
        super(props);
        this.handleClick = this.handleClick.bind(this);
        this.onOpenChange = this.onOpenChange.bind(this);
    }

    handleClick(e) {
        this.props.changeCurrent([e.key]);
        if (this.props.app.view === 'MobileView') {
            setTimeout(() => {
                this.props.toggleCollapsed();
                this.props.toggleOpenDrawer();
            }, 100);
        }
    }

    onOpenChange(newOpenKeys) {
        const {app, changeOpenKeys} = this.props;
        const latestOpenKey = newOpenKeys.find(
            key => !(app.openKeys.indexOf(key) > -1)
        );
        const latestCloseKey = app.openKeys.find(
            key => !(newOpenKeys.indexOf(key) > -1)
        );
        let nextOpenKeys = [];
        if (latestOpenKey) {
            nextOpenKeys = this.getAncestorKeys(latestOpenKey).concat(latestOpenKey);
        }
        if (latestCloseKey) {
            nextOpenKeys = this.getAncestorKeys(latestCloseKey);
        }
        changeOpenKeys(nextOpenKeys);
    }

    getAncestorKeys = key => {
        const map = {
            sub3: ['sub2']
        };
        return map[key] || [];
    };

    getMenuItem = ({singleOption, submenuStyle, submenuColor}) => {
        const {key, label, leftIcon, children, userLevel} = singleOption;
        const url = stripTrailingSlash(this.props.url);

        const isAllowed = Array.isArray(userLevel)
            ? userLevel.includes(this.props.loggedUser.roleLevel)
            : this.props.loggedUser.roleLevel >= userLevel;

        if (!!this.props.loggedUser && isAllowed) {
            if (!!children) {
                return (
                    <SubMenu
                        key={key}
                        title={
                            <span className="isoMenuHolder" style={submenuColor}>
                                <i className={leftIcon}/>
                                <span className="nav-text">
                                    {label}
                                </span>
                            </span>
                        }
                    >
                        {children.map(child => {
                            const linkTo = child.withoutDashboard
                                ? `/${child.key}`
                                : `${url}/${child.key}`;

                            const childIsAllowed = Array.isArray(child.userLevel)
                                ? child.userLevel.includes(this.props.loggedUser.roleLevel)
                                : this.props.loggedUser.roleLevel >= child.userLevel;

                            if (!!this.props.loggedUser && childIsAllowed) {
                                if (this.props.loggedUser.roleLevel === 2 && child.key === 'csv-upload') {
                                } else {
                                    return (
                                        <Menu.Item style={submenuStyle} key={child.key}>
                                            <Link style={submenuColor} to={linkTo}>
                                                <IntlMessages id={child.label}/>
                                            </Link>
                                        </Menu.Item>
                                    );
                                }
                            }
                        })}
                    </SubMenu>
                );
            } else {
                return (
                    <Menu.Item key={key}>
                        <Link to={`${url}/${key}`}>
                            <span className="isoMenuHolder" style={submenuColor}>
                                <i className={leftIcon}/>
                                <span className="nav-text">
                                    <IntlMessages id={label}/>
                                </span>
                            </span>
                        </Link>
                    </Menu.Item>
                );
            }
        }
    };

    render() {
        const {app, toggleOpenDrawer, height, loggedUser} = this.props;
        const collapsed = clone(app.collapsed) && !clone(app.openDrawer);
        const {openDrawer} = app;
        const mode = collapsed === true ? 'vertical' : 'inline';
        const onMouseEnter = () => {
            if (openDrawer === false) {
                toggleOpenDrawer();
            }
        };
        const onMouseLeave = () => {
            if (openDrawer === true) {
                toggleOpenDrawer();
            }
        };
        const customizedTheme = themes[themeConfig.theme];
        const styling = {
            backgroundColor: customizedTheme.backgroundColor
        };
        const submenuStyle = {
            backgroundColor: 'rgba(0,0,0,0.3)',
            color: customizedTheme.textColor
        };
        const submenuColor = {
            color: customizedTheme.textColor
        };
        return (
            <SidebarWrapper>
                <Sider
                    trigger={null}
                    collapsible={true}
                    collapsed={collapsed}
                    width={240}
                    className="isomorphicSidebar"
                    onMouseEnter={onMouseEnter}
                    onMouseLeave={onMouseLeave}
                    style={styling}
                >
                    <Logo collapsed={collapsed} userLevel={loggedUser.roleLevel}/>
                    <Scrollbars style={{height: height - 70}}>
                        <Menu
                            onClick={this.handleClick}
                            theme="dark"
                            className="isoDashboardMenu"
                            mode={mode}
                            openKeys={collapsed ? [] : app.openKeys}
                            selectedKeys={app.current}
                            onOpenChange={this.onOpenChange}
                        >
                            {options.map(singleOption =>
                                this.getMenuItem({submenuStyle, submenuColor, singleOption})
                            )}
                        </Menu>
                    </Scrollbars>
                </Sider>
            </SidebarWrapper>
        );
    }
}

export default connect(
    state => ({
        app: state.App,
        loggedUser: state.Auth.loggedUser,
        height: state.App.height
    }),
    {toggleOpenDrawer, changeOpenKeys, changeCurrent, toggleCollapsed}
)(Sidebar);
