import {FETCH_CSV_OPTIONS, FETCH_CSV_IMPORT, LOADER_TRUE, LOADER_FALSE} from './actions'

const initialState = {
    csvOptions: [],
    importResponse: null,
    loading: false,
}

export default (state = initialState, action) => {
    switch (action.type) {
        case FETCH_CSV_OPTIONS:
            return {...state, csvOptions: action.payload.data}
        case FETCH_CSV_IMPORT:
            return {...state, importResponse: action.payload.data}
        case LOADER_TRUE:
            return {...state, loading: true}
        case LOADER_FALSE:
            return {...state, loading: false}
        default:
            return state
    }
}