import axios from 'axios'
import CONSTANTS from '../../settings/constants'
import message from "../../components/common/feedback/message";
import {getCatalogItems} from "../tickets/actions";

// Action Names
export const FETCH_COMPANIES = 'FETCH_COMPANIES'

// API URLs
const {XONE_CASH_API} = CONSTANTS

export const fetchCompanies = (token) => async (dispatch) => {
    const {data} = await axios.get(
        `${XONE_CASH_API}/v1/collection/companies`,
        {headers: {'Authorization': 'Bearer ' + token}}
    )
    dispatch({type: FETCH_COMPANIES, payload: data || []})
}

export const saveCompany = (item, token) => async (dispatch) => {
    let url = `${XONE_CASH_API}/v1/collection/company/`
    try {
        if (!!item.id) {
            url += `${item.id}`
            await axios.put(url, item, {headers: {'Authorization': 'Bearer ' + token}})
        } else {
            await axios.post(url, item, {headers: {'Authorization': 'Bearer ' + token}})
        }
        dispatch(fetchCompanies(token))
        message.success('La Empresa se guardó exitosamente.')
    } catch (error) {
        message.error('Ha ocurrido un error al guardar la Empresa.')
    }
}

export const deleteCompany = (itemId, token) => async (dispatch) => {
    try {
        await axios.delete(`${XONE_CASH_API}/v1/collection/company/${itemId}`, {headers: {'Authorization': 'Bearer ' + token}})
        dispatch(fetchCompanies(token))
        message.success('La Empresa se eliminó exitosamente.')
    } catch (error) {
        message.error('Ha ocurrido un error al eliminar la Empresa.')
    }
}
