import React, { Component } from 'react'
import { connect } from 'react-redux'
import Popover from '../../components/common/uielements/popover'
import userpic from '../../image/user1.png'
import authAction from '../../redux/auth/actions'
import TopbarDropdownWrapper from './topbarDropdown.style'
import decode from 'jwt-decode'

const { logout } = authAction

class TopbarUser extends Component {
  constructor (props) {
    super(props)
    this.handleVisibleChange = this.handleVisibleChange.bind(this)
    this.hide = this.hide.bind(this)
    this.state = {
      visible: false,
      userId: decode(this.props.userInfo).id
    }
  }

  hide () {
    this.setState({ visible: false })
  }

  handleVisibleChange () {
    this.setState({ visible: !this.state.visible })
  }

  render () {
    const content = (
      <TopbarDropdownWrapper className="isoUserDropdown">
        <a className="isoDropdownLink" href={`/app/user-profile/${this.state.userId}`}>
          Perfil de usuario
        </a>
        <a className="isoDropdownLink" onClick={this.props.logout} href="# ">
          Cerrar Sesión
        </a>
      </TopbarDropdownWrapper>
    )

    return (
      <Popover
        content={content}
        trigger="click"
        visible={this.state.visible}
        onVisibleChange={this.handleVisibleChange}
        arrowPointAtCenter={true}
        placement="bottomLeft"
      >
        <div className="isoImgWrapper">
          <img alt="user" src={userpic} />
          <span className="userActivity online" />
        </div>
      </Popover>
    )
  }
}
export default connect(
  (state) => ({
    userInfo: state.Auth.idToken
  }),
  { logout }
)(TopbarUser)
