import {
  GET_ALL_TEMPLATES,
  SEND_BULK_SMS
} from './actions'

const initialState = {
  templates: [],
}

export default (state = initialState, action) => {
  switch (action.type) {
    case GET_ALL_TEMPLATES:
      return { ...state, templates: action.payload }
      case SEND_BULK_SMS:
      return { ...state, smsResponse: action.payload }
    default:
      return state
  }
}
