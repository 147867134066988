const actions = {
  CHECK_AUTHORIZATION: 'CHECK_AUTHORIZATION',
  LOGIN_REQUEST: 'LOGIN_REQUEST',
  LOGOUT: 'LOGOUT',
  LOGIN_SUCCESS: 'LOGIN_SUCCESS',
  LOGIN_ERROR: 'LOGIN_ERROR',
  AUTH_SUCCESS: 'AUTH_SUCCESS',
  GET_USER_INFO: 'GET_USER_INFO',
  checkAuthorization: () => ({ type: actions.CHECK_AUTHORIZATION }),
  login: (body) => ({
    type: actions.LOGIN_REQUEST,
    payload: body
  }),
  logout: () => ({
    type: actions.LOGOUT
  })
}
export default actions
