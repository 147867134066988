import axios from 'axios'
import CONSTANTS from '../../settings/constants'
import message from './../../components/common/feedback/message'
import * as moment from 'moment'

// Action Names
export const FETCH_MONTHLY_GOALS = 'FETCH_MONTHLY_GOALS'
export const GET_INVOICES_BY_CUSTOMER = 'GET_INVOICES_BY_CUSTOMER'
export const GET_INSTALLMENTS_BY_CREDIT = 'GET_INSTALLMENTS_BY_CREDIT'
export const GET_PAYMENTS_BY_CUSTOMER = 'GET_PAYMENTS_BY_CUSTOMER'
export const GET_INVOICE_DETAILS_BY_CREDIT = 'GET_INVOICE_DETAILS_BY_CREDIT'
export const GET_CLIENT_INFO = 'GET_CLIENT_INFO'
export const GET_ALL_COLLECTIONS = 'GET_ALL_COLLECTIONS'
export const SEND_CLIENT_EMAIL = 'SEND_CLIENT_EMAIL'
export const GET_COMPANIES_BY_AGENT = 'GET_COMPANIES_BY_AGENT'
export const GET_ALL_GOALS = 'GET_ALL_GOALS'
export const GET_CLIENT_ACCOUNT_STATUS = 'GET_CLIENT_ACCOUNT_STATUS'
export const FETCH_ACCOUNT_EXECUTIVES = 'FETCH_ACCOUNT_EXECUTIVES'
export const COLLECTIONS_REPORT = 'COLLECTIONS_REPORT'
export const BUDGET_REPORT = 'BUDGET_REPORT'
export const GENERATE_TICKETS = 'GENERATE_TICKETS'

// API URLs
const { XONE_CASH_API } = CONSTANTS

export const clearCollectionStore = () => async (dispatch) => {
  dispatch({ type: GET_INVOICES_BY_CUSTOMER, payload: [] })
  dispatch({ type: GET_PAYMENTS_BY_CUSTOMER, payload: [] })
  dispatch({ type: GET_CLIENT_INFO, payload: null })
}

export const fetchMonthlyGoals = (companyIds, token) => async (dispatch) => {
  const { data } = await axios.post(
    `${XONE_CASH_API}/v1/collection/companies/getGoalsByCompanyIds`,
    companyIds, { headers: { 'Authorization': 'Bearer ' + token } }
  )

  dispatch({ type: FETCH_MONTHLY_GOALS, payload: data })
}

export const getInvoicesByCustomer = (customerId, companyId, token) => async (dispatch) => {
  const { data } = await axios.get(
    `${XONE_CASH_API}/v1/credits/getByCustomerAndCompany?customerId=${customerId}&companyId=${companyId}`,
    { headers: { 'Authorization': 'Bearer ' + token } }
  )

  dispatch({ type: GET_INVOICES_BY_CUSTOMER, payload: data })
}

export const getInstallmentsByCreditId = (token, creditId, page = null, size = null) => async (dispatch) => {
  let url = `${XONE_CASH_API}/v1/credits/getInstallmentsByCreditId?creditId=${creditId}`
  if (!!page) {
    url += `&page=${page}`
  }
  if (!!size) {
    url += `&size=${size}`
  }
  const { data } = await axios.get(url, { headers: { 'Authorization': 'Bearer ' + token } })

  dispatch({ type: GET_INSTALLMENTS_BY_CREDIT, payload: data })
}

export const getPaymentsByCustomer = (token, customerId, companyId, page = null, size = null) => async (dispatch) => {
  let url = `${XONE_CASH_API}/v1/credits/getPaymentsByCustomerCompanyId?customerId=${customerId}&companyId=${companyId}`
  if (!!page) {
    url += `&page=${page}`
  } else {
    url += '&page=1'
  }
  if (!!size) {
    url += `&size=${size}`
  } else {
    url += '&size=5'
  }
  const { data } = await axios.get(url, { headers: { 'Authorization': 'Bearer ' + token } })

  dispatch({ type: GET_PAYMENTS_BY_CUSTOMER, payload: data })
}

export const getInvoiceDetailByCredit = (creditId, token) => async (dispatch) => {
  const { data } = await axios.get(
    `${XONE_CASH_API}/v1/credits/getInvoiceDetailsByCreditId?creditId=${creditId}`,
    { headers: { 'Authorization': 'Bearer ' + token } }
  )

  dispatch({ type: GET_INVOICE_DETAILS_BY_CREDIT, payload: data })
}

export const getClientInfo = (customerId, companyId, token) => async (dispatch) => {
  const { data } = await axios.get(
    `${XONE_CASH_API}/v1/collection/customerByCompany?customerId=${customerId}&companyId=${companyId}`,
    { headers: { 'Authorization': 'Bearer ' + token } }
  )

  dispatch({ type: GET_CLIENT_INFO, payload: data })
}

export const getAllCollections = (token, filtersData = null, page = null, size = null) => async (dispatch) => {
  let url = `${XONE_CASH_API}/v1/collection/getAll`;
  if (!page) {
    page = 1;
  }
  if (!size) {

    size = 20;
  }
  const { data } = await axios.get(url, {
    headers: { 'Authorization': 'Bearer ' + token }, params: {
      ...filtersData, page, size
    }
  })
  dispatch({ type: GET_ALL_COLLECTIONS, payload: data || [] })
}

export const sendClientEmail = (formData, token) => async () => {
  try {
    const data = await axios.post(
      `${XONE_CASH_API}/v1/collection/sendClientEmail`, formData,
      { headers: { 'Authorization': 'Bearer ' + token } }
    )
    if (!!data && data.data.success) {
      message.success('Se ha enviado el estado de cuenta correctamente al cliente')
    } else {
      message.error('Se ha producido un error al enviar el estado de cuenta al cliente')
    }
  } catch (error) {
    message.error('Se ha producido un error al enviar el estado de cuenta al cliente')
  }
}

export const getClientAccountStatus = (companyId, customerId, token) => async (dispatch) => {
  const { data } = await axios.get(
    `${XONE_CASH_API}/v1/collection/getCustomerStatusAccount?companyId=${companyId}&customerId=${customerId}`,
    { headers: { 'Authorization': 'Bearer ' + token } }
  )
  dispatch({ type: GET_CLIENT_ACCOUNT_STATUS, payload: data })
}

export const getCompaniesByAgent = (agentId, token) => async (dispatch) => {
  const { data } = await axios.get(
    `${XONE_CASH_API}/v1/queueManager/getCompaniesByAgentExternalId/${agentId}`,
    { headers: { 'Authorization': 'Bearer ' + token } }
  )

  dispatch({ type: GET_COMPANIES_BY_AGENT, payload: data })
}

export const getAllGoals = (token, page = null, size = null) => async (dispatch) => {
  let url = `${XONE_CASH_API}/v1/collection/collectionGoal/getAll`
  if (!!page) {
    url += `?page=${page}`
  } else {
    url += `?page=1`
  }
  if (!!size) {
    url += `&size=${size}`
  } else {
    url += `&size=10`
  }
  const { data } = await axios.get(url, { headers: { 'Authorization': 'Bearer ' + token } })
  dispatch({ type: GET_ALL_GOALS, payload: data || [] })
}

export const saveMonthlyGoals = (formData, token) => async (dispatch) => {
  const item = {
    company: formData.companyId,
    year: moment(formData.period, 'YYYY').year(),
    month: moment(formData.period, 'MM').month() + 1,
    monthCashRecoveryAmount: parseFloat(formData.monthAmount),
    monthCustomersRecoveryAmount: (parseInt(formData.clientsWeek1) + parseInt(formData.clientsWeek2) + parseInt(formData.clientsWeek3) + parseInt(formData.clientsWeek4)),
    jsonData: {
      week1: formData.week1,
      week2: formData.week2,
      week3: formData.week3,
      week4: formData.week4,
      clientsWeek1: formData.clientsWeek1,
      clientsWeek2: formData.clientsWeek2,
      clientsWeek3: formData.clientsWeek3,
      clientsWeek4: formData.clientsWeek4,
    }
  }
  try {
    if (!!formData.id) {
      await axios.put(
        `${XONE_CASH_API}/v1/collection/collectionGoal/edit/${formData.id}`, item,
        { headers: { 'Authorization': 'Bearer ' + token } }
      )
    } else {
      await axios.post(
        `${XONE_CASH_API}/v1/collection/collectionGoal/create`, item,
        { headers: { 'Authorization': 'Bearer ' + token } }
      )
    }
    dispatch(getAllGoals(token))
    message.success('Se guardó exitosamente la meta mensual')
  } catch (error) {
    message.error('Se ha producido un error al guardar la meta mensual, por favor revise los datos')
  }
}

export const fetchAccountExecutives = (token, companyId = null) => async (dispatch) => {
  let url = `${XONE_CASH_API}/v1/collection/accountExecutive/findAll`
  if (!!companyId) {
    url += `?companyId=${companyId}`
  }
  const { data } = await axios.get(
    url, { headers: { 'Authorization': 'Bearer ' + token } }
  )
  dispatch({ type: FETCH_ACCOUNT_EXECUTIVES, payload: data || [] })
}

export const getCollectionsReport = (token, filters) => async (dispatch) => {
  var url = `${XONE_CASH_API}/v1/collection/collectionsReport`
  if (!!filters) {
    if (!!filters.agentId) {
      url += `?agentExternalId=${filters.agentId}`
    }
    if (!!filters.companyId) {
      if (!!filters.agentId) {
        url += `&companyId=${filters.companyId}`
      } else {
        url += `?companyId=${filters.companyId}`
      }
    }
    if (!!filters.accountExecutiveId) {
      if (!!filters.agentId || !!filters.companyId) {
        url += `&accountExecutiveId=${filters.accountExecutiveId}`
      } else {
        url += `?accountExecutiveId=${filters.accountExecutiveId}`
      }
    }
    if (!!filters.period) {
      if (!!filters.agentId || !!filters.companyId || !!filters.statusTicket || !!filters.filterName) {
        url += `&from=${moment(filters.period[0]).format('YYYY-MM-DD')}&to=${moment(filters.period[1]).format('YYYY-MM-DD')}`
      } else {
        url += `?from=${moment(filters.period[0]).format('YYYY-MM-DD')}&to=${moment(filters.period[1]).format('YYYY-MM-DD')}`
      }
    }
  }
  try {
    const { data } = await axios.get(url, { headers: { 'Authorization': 'Bearer ' + token } })
    dispatch({ type: COLLECTIONS_REPORT, payload: data || [] })
  } catch (error) {
    message.error('Se ha producido un error al generar el reporte')
  }
}

export const clearCollectionsReport = () => (dispatch) => {
  dispatch({ type: COLLECTIONS_REPORT, payload: [] })
}

export const getBudgetReport = (token, filters) => async (dispatch) => {
  var url = `${XONE_CASH_API}/v1/collection/getBudgetReport`
  if (!!filters) {
    if (!!filters.period) {
      url += `?year=${moment(filters.period).format('YYYY')}&month=${moment(filters.period).format('MM')}`
    }
  }
  try {
    const { data } = await axios.get(url, { headers: { 'Authorization': 'Bearer ' + token } })
    dispatch({ type: BUDGET_REPORT, payload: data || [] })
  } catch (error) {
    message.error('Se ha producido un error al generar el reporte')
  }
}

export const clearBudgetReport = () => (dispatch) => {
  dispatch({ type: BUDGET_REPORT, payload: [] })
}

export const generateTicketsFromCollections = (token, filtersData = null) => async (dispatch) => {
  let url = `${XONE_CASH_API}/v1/ticketGenerator`;
  try {
    const { data } = await axios.post(
      url, { ...filtersData },
      { headers: { 'Authorization': 'Bearer ' + token } }
    )
    dispatch({ type: GET_ALL_COLLECTIONS, payload: data || [] })
    message.success('Tickets generados correctamente')
  } catch (error) {
    message.error('Se ha producido un error al generar los tickets')
  }

}
