import {
  FETCH_PHONES_BY_ID,
  GET_CALLS_BY_CONTACT,
  GET_ADDRESSES_BY_CONTACT,
  GET_CITIES_BY_PROVINCE,
  FETCH_EMAILS_BY_ID,
  GET_COMPANY_BY_ID
} from './actions'

const initialState = {
  phoneList: null
}

export default (state = initialState, action) => {
  switch (action.type) {
    case FETCH_PHONES_BY_ID:
      return { ...state, phoneList: action.payload }
    case FETCH_EMAILS_BY_ID:
      return { ...state, emails: action.payload }
    case GET_COMPANY_BY_ID:
      return { ...state, company: action.payload }
    case GET_CALLS_BY_CONTACT:
      return { ...state, callsRecord: action.payload }
    case GET_ADDRESSES_BY_CONTACT:
      return { ...state, addressList: action.payload }
    case GET_CITIES_BY_PROVINCE:
      return { ...state, citiesList: action.payload }
    default:
      return state
  }
}
