import {
  FETCH_COMPANIES
} from './actions'

const initialState = {
  all: null
}

export default (state = initialState, action) => {
  switch (action.type) {
    case FETCH_COMPANIES:
      return { ...state, all: action.payload }
    default:
      return state
  }
}
