import {all, takeEvery, put, fork, call} from 'redux-saga/effects'
import {push} from 'react-router-redux'
import {getToken, clearToken} from '../../helpers/utility'
import axios from 'axios'
import CONSTANTS from '../../settings/constants'
import actions from './actions'
import decode from 'jwt-decode'

// API URLs
const {XONE_CASH_API} = CONSTANTS

export function fetchLogin(body) {
    return axios.post(`${XONE_CASH_API}/v1/login`, body)
}

export function* loginRequest() {
    yield takeEvery(actions.LOGIN_REQUEST, function* (action) {
        try {
            yield put({
                type: actions.LOGIN_ERROR,
                error: null
            })
            const {data} = yield call(fetchLogin, action.payload)
            yield put({
                type: actions.LOGIN_SUCCESS,
                token: data.accessToken,
            })
        } catch (exception) {
            yield put({
                type: actions.LOGIN_ERROR,
                error: CONSTANTS.ERROR_LOGIN_MESSAGE
            })
        }
    })
}

export function* loginSuccess() {
    yield takeEvery(actions.LOGIN_SUCCESS, function* (payload) {
        yield localStorage.setItem('id_token', payload.token)
        const data = decode(payload.token)
        yield put({
            type: actions.GET_USER_INFO,
            payload: data,
        })
        if (!!data.roleLevel && data.roleLevel === 1)
            yield put(push('/app/csv-upload'))
        else
            yield put(push('/app/management-trays'))
    })
}

export function* logout() {
    yield takeEvery(actions.LOGOUT, function* () {
        clearToken()
        yield put(push('/signin'))
    })
}

export function* checkAuthorization() {
    yield takeEvery(actions.CHECK_AUTHORIZATION, function* () {
        const token = getToken().get('idToken')
        if (token) {
            yield put({
                type: actions.AUTH_SUCCESS,
                token,
            })
            const data = decode(token)
            const now = new Date().getTime() / 1000
            if (now - data.iat > 86400) {
                clearToken()
                yield put(push('/signin'))
            } else {
                yield put({
                    type: actions.GET_USER_INFO,
                    payload: data,
                })
            }
        }
    })
}

export default function* rootSaga() {
    yield all([
        fork(checkAuthorization),
        fork(loginRequest),
        fork(loginSuccess),
        fork(logout)
    ])
}
